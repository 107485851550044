<template>
  <el-dropdown class="justify-self-end" @command="setYear">
    <el-button type="info">
      {{ currentYear }}
      <el-icon class="el-icon--right">
        <ChevronUpDownIcon />
      </el-icon>
    </el-button>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item v-for="year in possibleYears" :key="year" :command="year">{{ year }}</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import { ChevronUpDownIcon } from '@heroicons/vue/24/solid';
import { MediaGetters } from '@/lib/MediaGetters';

export default{
  name: 'YearMenu',
  emits: ['yearChanged'],
  components: {
    ChevronUpDownIcon
  },
  data() {
    return {
    }
  },
  methods: {
    setYear(year) {
      this.currentYear = year;
      this.$emit('yearChanged', year.value);
    },
  },
  computed: {
    possibleYears() {
      if(!this.$store.getters.dataCounts) return [];
      return [2023];// [...new Set(this.$store.getters.dataCounts.map((item) => item.year))];
    },
    currentYear: {
      get() {
        return this.$store.getters.currentYear
      },
      set(year) {
        this.$store.commit('SET_CURRENT_YEAR', year)
      },
    }
  },
  mounted() {
    MediaGetters.getDataCounts().then(() => {
      this.setYear(this.currentYear)
    })
  }
}
</script>