export const CreativeIntelligence = {
  setPageTitle: function(title, fullTitle = true) {
    document.title = title + (fullTitle ? ' | Creative Intelligence' : '');
  },
  spinner: {
//    svg: '<g><path d="M979.53,207.56c-9.02,13.26-21.72,29.4-31.71,47.08c-24.43,43.2-18.22,85.11,8.71,125.02c7.84,11.62,16.69,22.55,26.26,35.38c-2.96-2.31-4.54-3.68-6.24-4.86c-15.73-10.85-30.72-23.04-47.37-32.21c-33.26-18.33-67.52-17.89-101.58-0.99c-20.01,9.93-37.47,23.44-55.08,37.36c1.32-1.61,2.62-3.25,3.98-4.82c15.39-17.8,29.12-36.71,38.13-58.64c14.04-34.2,10.29-67.29-8-98.66c-8-13.72-18.12-26.2-27.36-39.2c-1.69-2.37-3.73-4.5-4.85-7.56c5.97,4.81,11.87,9.7,17.93,14.4c17.79,13.8,36.79,25.38,58.74,31.36c32.39,8.82,62.09,1.2,89.64-16.02C955.4,226.01,969.02,215.1,979.53,207.56z"/><path d="M749.89,587.49c0.63,1.06,1.21,2.16,1.72,3.31c11.81,26.35-9.81,60.34-20.23,83.93c-8.2,18.56-18.11,36.36-29.52,53.14c-22.6,33.23-51.11,62.42-83.89,85.68c-56.54,40.1-124.95,61.78-194.25,61.78c-185.71,0-336.24-150.53-336.24-336.24c0-185.71,150.53-336.24,336.24-336.24c43.87,0,87.62,8.56,128.18,25.3c62.6,25.83,116.67,70.79,153.67,127.5c12.72,19.5,23.4,40.33,31.82,62.04c8.13,20.96,16.85,44.26,18.03,66.86c0.3,5.78,0.13,11.68-1.7,17.17c-3.22,9.64-11.35,17.04-20.51,21.42c-36.4,17.39-90.08,6.89-123.25-13.27c-30-17.98-57.81-41.25-83.8-67.64c-52.51-53.28-92.21-114.32-104.24-194.25c-13.58,131.65-162.95,284.13-289.82,299.77c75.14,12.93,134.76,53.41,186.87,106.43c51.99,52.76,91.56,113.16,102.04,183.77c17.44-61.93,46.3-120.9,88.82-169.52c22.68-25.94,48.94-48.64,77.76-67.51c39.79-26.05,80.8-48.01,129.68-34.65C730.09,569.77,743.19,576.27,749.89,587.49z"/><path d="M877.64,871.49L877.64,871.49c-46.43,0-84.06-37.64-84.06-84.06v-287.1c0-46.43,37.64-84.06,84.06-84.06h0c46.43,0,84.06,37.64,84.06,84.06v287.1C961.7,833.85,924.07,871.49,877.64,871.49z"/>/g>',
    svg: '<g ><path class="path svg-logo-c" d="M979.53,207.56c-9.02,13.26-21.72,29.4-31.71,47.08c-24.43,43.2-18.22,85.11,8.71,125.02c7.84,11.62,16.69,22.55,26.26,35.38c-2.96-2.31-4.54-3.68-6.24-4.86c-15.73-10.85-30.72-23.04-47.37-32.21c-33.26-18.33-67.52-17.89-101.58-0.99c-20.01,9.93-37.47,23.44-55.08,37.36c1.32-1.61,2.62-3.25,3.98-4.82c15.39-17.8,29.12-36.71,38.13-58.64c14.04-34.2,10.29-67.29-8-98.66c-8-13.72-18.12-26.2-27.36-39.2c-1.69-2.37-3.73-4.5-4.85-7.56c5.97,4.81,11.87,9.7,17.93,14.4c17.79,13.8,36.79,25.38,58.74,31.36c32.39,8.82,62.09,1.2,89.64-16.02C955.4,226.01,969.02,215.1,979.53,207.56z"/><path class="path svg-logo-i" d="M749.89,587.49c0.63,1.06,1.21,2.16,1.72,3.31c11.81,26.35-9.81,60.34-20.23,83.93c-8.2,18.56-18.11,36.36-29.52,53.14c-22.6,33.23-51.11,62.42-83.89,85.68c-56.54,40.1-124.95,61.78-194.25,61.78c-185.71,0-336.24-150.53-336.24-336.24c0-185.71,150.53-336.24,336.24-336.24c43.87,0,87.62,8.56,128.18,25.3c62.6,25.83,116.67,70.79,153.67,127.5c12.72,19.5,23.4,40.33,31.82,62.04c8.13,20.96,16.85,44.26,18.03,66.86c0.3,5.78,0.13,11.68-1.7,17.17c-3.22,9.64-11.35,17.04-20.51,21.42c-36.4,17.39-90.08,6.89-123.25-13.27c-30-17.98-57.81-41.25-83.8-67.64c-52.51-53.28-92.21-114.32-104.24-194.25c-13.58,131.65-162.95,284.13-289.82,299.77c75.14,12.93,134.76,53.41,186.87,106.43c51.99,52.76,91.56,113.16,102.04,183.77c17.44-61.93,46.3-120.9,88.82-169.52c22.68-25.94,48.94-48.64,77.76-67.51c39.79-26.05,80.8-48.01,129.68-34.65C730.09,569.77,743.19,576.27,749.89,587.49z"/><path class="path svg-logo-dot" d="M877.64,871.49L877.64,871.49c-46.43,0-84.06-37.64-84.06-84.06v-287.1c0-46.43,37.64-84.06,84.06-84.06h0c46.43,0,84.06,37.64,84.06,84.06v287.1C961.7,833.85,924.07,871.49,877.64,871.49z"/></g>',
    viewBox: '0 0 1080 1080'
  },
  brandColors: ['#ffffff','#3030ce','#00dcb3','#ffc300','#ff95d4','#7f3efa','#19191b']
}