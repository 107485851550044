import firebase from "../firebase/init";

export default {
    methods: {
        async getVideos() {
            //get cached data if available
            if (this.$store.state.videoData && this.$store.state.videoData.length > 0) {
              return;
            }
      
            const db = firebase.firestore();
            var videoQuery = db.collection("videos").get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  const data = doc.data();
                  this.videoData.push({
                    id: doc.id,
                    name: data.videoId,
                    source: data.sourceId,
                    year: data.videoYear,
                    duration: Math.floor(data.length/60) + ":" + (data.length % 60 < 10 ? '0' : '') + data.length % 60,
                    analyzed: data.analyzed,
                    title: data.title || data.videoId,
                    agency: data.agency,
                    brand: data.brand,
                    category: data.category,
                    city: data.city,
                    country: data.country,
                    type: 'Video'
                  });
                });
              });
            Promise.all([videoQuery]).then(async () => {
              this.videoTotal = this.videoData.length;
              //cache data
              await this.$store.commit('SET_VIDEO_DATA', this.videoData);
              console.log('stored', this.$store.state.videoData.length);
            });
        },
        async getImages() {
          //get cached data if available
          if (this.$store.state.imageData && this.$store.state.imageData.length > 0) {
           return;
          }
    
          const db = firebase.firestore();
          var imageQuery = db.collection("images").where('sourceId','==','direct').get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                const data = doc.data();
                this.imageData.push({
                  id: doc.id,
                  name: data.imageId,
                  source: data.sourceId,
                  year: data.imageYear,
                  analyzed: data.analyzed,
                  title: data.title,
                  agency: data.agency,
                  brand: data.brand,
                  category: data.category,
                  city: data.city,
                  country: data.country,
                  type: data.type == 'pdf' ? 'PDF' : 'Image'
                });
              });
            });
          Promise.all([imageQuery]).then(async () => {
            //cache data
            await this.$store.commit('SET_IMAGE_DATA', this.imageData);
            console.log('stored', this.$store.state.imageData.length)
          });
        }
    
    }
}