import { createApp } from 'vue'
import App from './App.vue'
import router from './routes/index'
import store from './store'
import VueLogger from 'vuejs3-logger'
import './styles.css';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './overrides.css';
import LogRocket from 'logrocket';
import VueMobileDetection from "vue-mobile-detection";
import { createMetaManager } from 'vue-meta'

const isProduction = process.env.NODE_ENV === 'production';
const loggerOptions = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
};

createApp(App).use(router).use(createMetaManager()).use(store).use(ElementPlus).use(VueLogger, loggerOptions).use(VueMobileDetection).mount('#app')


if (isProduction) {
    LogRocket.init('7hwpej/new-york-festivals');
    if(store.getters.user.loggedIn) {
        LogRocket.identify(store.getters.user.data.email);
    }
}
