import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyAeYFDy4svHOwYop7ojGboLrajqvPFEIM0",
    authDomain: "praxis-effort-372113.firebaseapp.com",
    projectId: "praxis-effort-372113",
    storageBucket: "praxis-effort-372113.appspot.com",
    messagingSenderId: "709734085686",
    appId: "1:709734085686:web:00b912c6ec6e7886b39497"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

//initialize firebase auth
const auth = getAuth()


export { app, auth }