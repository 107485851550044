export const ImageAnnotations = {
  getEmotionEmojis: function(item) {
    if(item.emotions != undefined && item.emotions.length > 0) {
      var emoji = [];
      item.emotions.forEach((emotion) => {
        switch(emotion.toUpperCase()) {
          case 'HAPPY':
            emoji.push(
              {
              emotion: 'HAPPY',
              emoji: '😃'}
              );
            break;
          case 'SAD':
            emoji.push({
              emotion: 'SAD',
              emoji: '😢'
            });
            break;
          case 'ANGRY':
            emoji.push({
              emotion: 'ANGRY',
              emoji: '😡'
            })
            break;
          case 'SURPRISED':
            emoji.push({
              emotion: 'SURPRISED',
              emoji: '😮'
            })
            break;
          case 'CALM':
            emoji.push({
              emotion: 'CALM',
              emoji: '😌'
            })
            break;
          case 'CONFUSED':
            emoji.push({
              emotion: 'CONFUSED',
              emoji: '😕'
            })
            break;
          case 'DISGUSTED':
            emoji.push({
              emotion: 'DISGUSTED',
              emoji: '🤢'
            })
            break;
          case 'FEAR':
            emoji.push({
              emotion: 'FEAR',
              emoji: '😨'
            })
            break;
          case 'UNKNOWN':
            emoji.push({
              emotion: 'UNKNOWN',
              emoji: '😐'
            })
            break;
          default:
            emoji.push({
              emotion: 'UNKNOWN',
              emoji: '😐'
            })
        }
      });
      return emoji
    } else {
      return '';
    }
  },
  getPeople: function(item) {
    return item.faces.map((face) => {
      return face.ageRange + ' ' + face.gender;
    }).join(', ');
  },
  isDark: function(item) {
      //check if main color is dark or light
      const color = item.colorPalette.length > 1 ? item.colorPalette[0]: item.colorPalette[0];
      const r = parseInt(color.hexCode.substr(1, 2), 16);
      const g = parseInt(color.hexCode.substr(3, 2), 16);
      const b = parseInt(color.hexCode.substr(5, 2), 16);
      const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
      if (yiq >= 144) {
          return false;
      } else {
          return true;
      }
  },
  getBackgroundColor: function(item) {
      if(item.colorPalette.length > 1) {
          var filteredColors = [item.colorPalette[0]]; // add the first color
          if (item.colorPalette[1].pixelPercent > (item.colorPalette[0].pixelPercent / 3)) {
              filteredColors.push(item.colorPalette[1]);
          }
          if(filteredColors.length == 1) {
              return filteredColors[0].hexCode;
          }
          //create css gradient from color palette
          let gradient = 'linear-gradient(90deg, ';
          filteredColors.map((color) => {
              gradient += color.hexCode + ', ';// + ' ' + color.pixelPercent + '%, ';
          });
          gradient = gradient.slice(0, -2);
          gradient += ')';
          return gradient;
      } else {
          return item.colorPalette[0].hexCode;
      }
  },
  emotionEmojis: [
    {
      emotion: 'HAPPY',
      emoji: '😃'
    },
    {
      emotion: 'SAD',
      emoji: '😢'
    },
    {
      emotion: 'ANGRY',
      emoji: '😡'
    },
    {
      emotion: 'SURPRISED',
      emoji: '😮'
    },
    {
      emotion: 'CALM',
      emoji: '😌'
    },
    {
      emotion: 'CONFUSED',
      emoji: '😕'
    },
    {
      emotion: 'DISGUSTED',
      emoji: '🤢'
    },
    {
      emotion: 'FEAR',
      emoji: '😨'
    },
    {
      emotion: 'UNKNOWN',
      emoji: '😐'
    }
  ]
};
  