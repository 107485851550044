<template>
  <div>
    <el-button @click="goBack">Back</el-button>
    <p class="my-5 text-xl">{{ agency }}</p>
    <media-list v-if="videoData.length > 0" :mediaData="videoData" ref="agencyVideoListComponentRef" hideSearch=true label="AgencyVideos"/>
    <media-list v-if="imageData.length > 0" :mediaData="imageData" ref="agencyImageListComponentRef" hideSearch=true label="AgencyImages"/>
  </div>
</template>
  
<script>
import navMixin from '../mixins/navMixin.js';
import mediaQuery from '../mixins/mediaQuery.js';
import MediaList from '../components/MediaList.vue';

export default {
  name: "ViewAgencyComponent",
  mixins: [navMixin, mediaQuery],
  components: {
    MediaList,
  },
  data() {
    return {
      agency: this.$route.params.agency
    };
  },
  created() {
    this.getVideos().then (() => {
      this.$refs.agencyVideoListComponentRef?.init();
    })
    this.getImages().then (() => {
      this.$refs.agencyImageListComponentRef?.init();
    })
  },
  computed: {
    videoData() {
      return this.$store.state.videoData.filter((video) => {
        return video.agency === this.agency;
      });
    },
    imageData() {
      return this.$store.state.imageData.filter((image) => {
        return image.agency === this.agency;
      });
    }
  }
}
</script>
