<template>
  <div>
    <el-button @click="goBack">Back</el-button>
    <VideoViewer v-if="type == 'Video'" ref="videoViewerComponent" @set-prompt="setPrompt" :videoId=id :offset=offset></VideoViewer>
    <ImageViewer v-if="type == 'Image' || type == 'PDF'" @set-prompt="setPrompt" :imageId=id></ImageViewer>
  </div>
</template>
  
<script>
import VideoViewer from '../components/VideoViewer.vue'
import ImageViewer from '../components/ImageViewer.vue'
import navMixin from '../mixins/navMixin.js';

export default {
  name: "ViewMediaComponent",
  mixins: [navMixin],
  components: {
    VideoViewer,
    ImageViewer
  },
  methods: {
    setPrompt(tag, tagType) {
      this.$emit('setPrompt', tag, tagType);
    }
  },
  data() {
    return {
      type: this.$route.params.type,
      id: this.$route.params.id,
      offset: this.$route.params.offset || '0'
    };
  }
}
</script>
