<template>
  <video ref="videoPlayer" class="video-js"></video>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';
import 'video.js/dist/video.min.js';

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
      init() {
          var currentOffset = -1;
          this.player = videojs(this.$refs.videoPlayer, this.options, () => {
              //this.player.log('onPlayerReady', this);
          });
          this.player.on('timeupdate', () => {
            const thisOffset = Math.round(this.player.currentTime());
            if(thisOffset != currentOffset) {
              currentOffset = thisOffset;
              this.$emit('offsetUpdate', currentOffset);
            }
          });
      },
      offsetJump(offset) {
        this.player.currentTime(offset);
        this.player.pause();
      },
      togglePlay() {
        if(this.player.paused()) {
          this.player.play();
        } else {
          this.player.pause();
        }
      }
  },
  data() {
    return {
      player: null
    }
  },
  mounted() {
      //this.init();
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>