<template>
  <el-tabs v-model="currentTab" class="" @tab-click="setCurrentTab">
    <el-tab-pane label="Videos" name="Videos">
      <media-list :mediaData="$store.state.videoData" ref="videoListComponentRef" label="Videos" />
    </el-tab-pane>
    <el-tab-pane label="Images" name="Images">
      <media-list :mediaData="$store.state.imageData" ref="imageListComponentRef" label="Images" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { ref } from "vue";
import { MediaGetters } from "../lib/MediaGetters";
import MediaList from "./MediaList.vue";

export default {
  components: {
    MediaList
  },
  data() {
    return {
      name: "",
      date: new Date().toISOString().slice(0, 10),
      videoData: [],
      paginatedVideoData: [],
      imageData: [],
      paginatedImageData: [],
      loading: false,
      pageSize: 10,
      currentImagePage: 1,
      currentVideoPage: 1,
      currentTab: 'Videos',
      MediaGetters: MediaGetters
    };
  },
  setup() {
    const activeName = ref("first");
    return {
      activeName,
    };
  },
  methods: {
    setCurrentTab(tab) {
      //save current tab name to store
      this.$store.commit('SET_CURRENT_TAB', tab.props.name);
      this.currentTab = tab.props.name;
    }
  },
  async mounted() {
    MediaGetters.getVideos().then(() => {
      this.$refs.videoListComponentRef.init();
    });
    MediaGetters.getImages().then(() => {
      this.$refs.imageListComponentRef.init();
    });
    //get current page from store
    this.setCurrentTab({props: {name: this.$store.state.appState.currentTab || 'Videos'}});
    this.loading = false;
  }
};


</script>