<template>
  <div class="grid grid-cols-2 justify-items-stretch">
    <div v-if="!hideSearch" class="w-1/2"><el-input v-model="searchText" placeholder="Search by title, brand, agency, etc." :onkeyup="searchMedia" /></div>
    <YearMenu @year-changed="yearChanged" />
  </div>
  <el-table
    :data="paginatedMediaData"
    width="1200"
    row-class-name="clickable"
    @row-click="viewMedia"
    @header-click="sortMedia"
    id="mediaList"
    class="mt-3 min-h-[300px]"
  >
    <el-table-column label="Title" prop="title" width="400"> </el-table-column>
    <el-table-column label="Agency" prop="agency" width="250"> </el-table-column>
    <el-table-column label="Brand" prop="brand" width="200"> </el-table-column>
    <el-table-column label="Category" prop="category" width="400"> </el-table-column>
    <el-table-column v-if="this.label.includes('Videos')" label="Duration" prop="duration" width="100"> </el-table-column>
   </el-table>
  <div class="my-5 flex justify-center">
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next"
      :total="total"
      :hide-on-single-page="true"
      :background="true"
    >
    </el-pagination>
  </div>
  <LoaderOverlay v-if="needsData" :data-loaded="dataLoaded"></LoaderOverlay>
</template>

<script>
import YearMenu from './YearMenu.vue';
import mitt from 'mitt';
import { CreativeIntelligence } from '@/lib/CreativeIntelligence';
import LoaderOverlay from '@/components/LoaderOverlay.vue';

const eventBus = mitt()

export default {
  data() {
    return {
      name: "",
      date: new Date().toISOString().slice(0, 10),
      mediaList: this.mediaData,
      paginatedMediaData: [],
      dataLoaded: false,
      pageSize: 8,
      currentPage: 1,
      total: 0,
      sortOrder: "desc",
      sortField: "name",
      searchText: '',
      eventBus,
      CreativeIntelligence: CreativeIntelligence,
      needsData: true
    };
  },
  components: {
    YearMenu,
    LoaderOverlay
  },
  props: ['mediaData', 'label','hideSearch'],
  methods: {
    sortMedia(column) {
      if(this.sortField === column.property) {
        this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
      } else {
        this.sortOrder = 'asc';
        this.sortField = column.property;
      }
      this.mediaList.sort((a, b) => {
        if (a[column.property] < b[column.property]) {
          return this.sortOrder == 'asc' ? -1 : 1;
        }
        if (a[column.property] > b[column.property]) {
          return this.sortOrder == 'desc' ? -1 : 1;
        }
        return 0;
      });
      this.currentPage = 1;
      this.getPaginatedMediaData();
    },
    viewMedia(row) {
      this.$router.push({
        name: "View",
        params: {
          type: row.type,
          id: row.id,
        },
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      //save current page to store
      this.$store.commit('SET_CURRENT_PAGE', { page: val, label: this.label });
      this.getPaginatedMediaData();
    },
    getPaginatedMediaData() {
      this.paginatedMediaData = this.filteredMediaData().slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
    },
    filteredMediaData() {
      if(this.currentYear) {
        this.mediaList = this.mediaData.filter((media) => media.year == this.currentYear);
      } else {
        this.mediaList = this.mediaData;
      }
      if(!this.searchText) {
        return this.mediaList;
      }
      return this.mediaList.filter((media) => {
        return media.title?.toLowerCase().includes(this.searchText.toLowerCase()) ||
          media.brand?.toLowerCase().includes(this.searchText.toLowerCase()) ||
          media.category?.toLowerCase().includes(this.searchText.toLowerCase()) ||
          media.agency?.toLowerCase().includes(this.searchText.toLowerCase()) ||
          media.type?.toLowerCase().includes(this.searchText.toLowerCase()) ||
          media.id?.toLowerCase().includes(this.searchText.toLowerCase())
      });
    },
    yearChanged() {
      this.getPaginatedMediaData();
      this.total = this.filteredMediaData().length;
    },
    searchMedia() {
      this.currentPage = 1;
      this.$store.commit('SET_CURRENT_PAGE', { page: 1, label: this.label });
      this.total = this.filteredMediaData().length;
      this.getPaginatedMediaData();
    },
    init() {
      this.mediaList = this.mediaData;
      this.handleCurrentChange(this.$store.state.appState.currentPage.filter((tab) => { return tab.label == this.label }).map((tab) => tab.page).find(() => true ) || 1);
      this.total = this.mediaList.length;
    }
  },
  mounted() {
    this.needsData = this.$store.state.videoData.length > 0 ? false : true;
  },
  computed: {
    currentYear: {
        get() {
          return this.$store.getters.currentYear
        },
        set(year) {
          this.$store.commit('SET_CURRENT_YEAR', year)
        },
      }
  },
  updated() {
      this.dataLoaded = true
  }
};


</script>