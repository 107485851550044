import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue';
import Dashboard from '../views/Dashboard.vue';
import ViewMedia from '../views/ViewMedia.vue';
import ViewAgency from '../views/ViewAgency.vue';
import ViewBrand from '../views/ViewBrand.vue';
import ViewData from '../views/ViewData.vue';
import Gallery from '../views/Gallery.vue';
import store from '../store';

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: 'Login'
        }
    },
    {
        path: '/dashboard',
        alias: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            title: 'Creative Intelligence',
            titleTemplate: '%s | NYF',
            authRequired: true,
        }
    },
    {
        path: '/view/:type:/:id/:offset?',
        name: 'View',
        component: ViewMedia,
        meta: {
            authRequired: true
        }
    },
    {
        path: '/agency/:agency',
        name: 'Agency',
        component: ViewAgency,
        meta: {
            title: 'Agency',
            authRequired: true
        }
    },
    {
        path: '/brand/:brand',
        name: 'Brand',
        component: ViewBrand,
        meta: {
            title: 'Brand',
            authRequired: true
        }
    },
    {
        path: '/data',
        name: 'Data',
        component: ViewData,
        meta: {
            title: 'Data',
            authRequired: true
        }
    },
    {
        path: '/gallery/:type/:tag',
        name: 'Gallery',
        component: Gallery,
        meta: {
            title: 'Gallery',
            authRequired: true
        }
    }
]


const router = createRouter({
    history: createWebHistory(),
    routes
  })

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  
    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  
    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) {
      document.title = nearestWithTitle.meta.title;
    } else if(previousNearestWithMeta) {
      document.title = previousNearestWithMeta.meta.title;
    }
  
    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  
    // Skip rendering meta tags if there are none.
    if(nearestWithMeta) {
    
      // Turn the meta tag definitions into actual elements in the head.
      nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');
    
        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });
    
        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');
    
        return tag;
      })
      // Add the meta tags to the document head.
      .forEach(tag => document.head.appendChild(tag));
    }
    
    if (to.matched.some(record => record.meta.authRequired)) {
      
      if (store.getters.user.loggedIn) {
            next();
        } else {
            next({
                path: '/login',
            });
        }
    } else {
        next();
    }
  });
    
export default router