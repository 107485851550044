<template>
  <div v-if="$isMobile()" id="mobileContainer">
    <prompt-builder ref="promptBuilerMobileRef" @set-prompt="setPrompt"></prompt-builder>
  </div>
  <div v-else>
    <MediaListTabs></MediaListTabs>
  </div>
</template>

<style scoped>
</style>
<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {computed} from "vue";
import MediaListTabs from '../components/MediaListTabs.vue'
import PromptBuilder from '../components/PromptBuilder.vue'

export default {
  name: "DashboardComponent",
  metaInfo: {
    title: 'Creative Intelligence'
  },
  components: {
    MediaListTabs,
    PromptBuilder
  },

  setup() {
    const store = useStore()
    const router = useRouter()

    const user = computed(() => {
      return store.getters.user;
    });

    const signOut = async () => {
      await store.dispatch('logOut')
      router.push('/login')
    }

    return {user,signOut}
  }

};
</script>