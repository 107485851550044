<template>
  <div id="imageHeader" class="w-full grid -mt-5">
    <div class="justify-self-center mb-4 text-xl">"{{ masterImage.title }}" ( <span class="clickable" @click= "viewBrand(masterImage.brand)">{{ masterImage.brand }}</span> / <span class="clickable" @click= "viewAgency(masterImage.agency)">{{ masterImage.agency }}</span> )</div>
  </div>
  <div id="imageInfo" class="grid grid-cols-2 gap-x-5">
    <div class="w-full">
      <el-scrollbar max-height="80vh">
        <el-card v-for="(image, index) in imageList" :key="image.imageId">
          <el-image :src="image.url" placeholder="loading image" @click="setCurrentImage(index)" />
        </el-card>
      </el-scrollbar>
      <div><PencilSquareIcon @click="toggleEditDrawer" class="editIcon icon my-5 secret clickable" /></div>
      <el-drawer v-model="editDrawerIsOpen" :withHeader="false" :direction="'ltr'" size="50%">
        <el-form ref="editForm" :model="masterImage" label-width="120px">
          <el-form-item label="Title">
            <el-input v-model="masterImage.title" required />
          </el-form-item>
          <el-form-item label="Brand">
            <el-input v-model="masterImage.brand" required />
          </el-form-item>
          <el-form-item label="Agency">
            <el-input v-model="masterImage.agency" required />
          </el-form-item>
          <el-form-item label="City">
            <el-input v-model="masterImage.city" />
          </el-form-item>
          <el-form-item label="Country">
            <el-input v-model="masterImage.country" required />
          </el-form-item>
          <el-form-item label="Category">
            <el-input v-model="masterImage.category" />
          </el-form-item>
          <el-form-item label="Year">
            <el-input v-model="masterImage.imageYear" />
          </el-form-item>
          <el-form-item class="mt-10">
            <el-tooltip class="item" effect="dark" content="Think before saving!" placement="top">
              <el-button type="primary" @click="editFormSubmit">Submit</el-button>
            </el-tooltip>
          </el-form-item>
        </el-form>
      </el-drawer>
    </div>
    <div class="w-full">
      <div v-if="currentImage?.annotations" class="flex flex-row-reverse text-xs mr-5 my-5">
          <p class="text-xs"><span class="font-black">Tip:</span> Click on an Emotion, Action, Expression, or Object to add it to your Prompt!</p>
          <c-i-logo color="white" border="black" class="w-4 h-4 mr-1"></c-i-logo>
        </div>
      <el-card v-if="currentImage?.annotations" :key="currentImage.annotations.imageId" :style="'background: ' + currentImage.annotations.backgroundColor + '; color: ' + currentImage.annotations.textColor">
        <div class="clearfix">
          <p v-if="currentImage.annotations.emotions">EMOTIONS:
            <span v-for="(emotion, index) in currentImage.annotations.emotions" :key="emotion" class="clickable" @click="confirmPrompt(emotion)">{{ ImageAnnotations.emotionEmojis.find((emotionEmoji) => emotionEmoji.emotion == emotion).emoji }}<span>{{ index < currentImage.annotations.emotions.length - 1 ? ', ' : '' }}</span></span>
          </p>
          <p v-if="currentImage.annotations.actions?.length">ACTIONS: 
            <span v-for="(action, index) in currentImage.annotations.actions" :key="action" class="clickable" @click="confirmPrompt(action)">{{ action }}<span>{{ index < currentImage.annotations.actions.length - 1 ? ', ' : '' }}</span></span>
          </p>
          <p v-if="currentImage.annotations.expressions?.length">EXPRESSIONS: 
            <span v-for="(expression, index) in currentImage.annotations.expressions" :key="expression" class="clickable" @click="confirmPrompt(expression)">{{ expression }}<span>{{ index < currentImage.annotations.expressions.length - 1 ? ', ' : '' }}</span></span>
          </p>
          <p v-if="currentImage.annotations.faces?.length">PEOPLE: {{ ImageAnnotations.getPeople(currentImage.annotations) }}</p>
          <p>OBJECTS: 
            <span v-for="(object, index) in currentImage.annotations.objects" :key="object" class="clickable" @click="confirmPrompt(object)">{{ object }}<span>{{ index < currentImage.annotations.objects.length - 1 ? ', ' : '' }}</span></span>
          </p>
        </div>
      </el-card>
    </div>
  </div>
  <el-dialog v-model="promptClicked" title="Prompt Builder" width="30%" center>
    <span>
      Add <b>{{ capitalCase(selectedTag) }}</b> to prompt?
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="promptClicked = false">Cancel</el-button>
        <el-button type="primary" @click="setPrompt(selectedTag)">
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import firebase from "../firebaseInit"
import { ImageAnnotations } from "@/lib/ImageAnnotations"
import navMixin from "@/mixins/navMixin"
import { PencilSquareIcon } from '@heroicons/vue/24/solid';
import { ElNotification } from 'element-plus';
import { capitalCase } from 'capital-case';
import { CreativeIntelligence } from '../lib/CreativeIntelligence';
import CILogo from '../components/CILogo';

const db = firebase.firestore();

export default {
  props: ['imageId'],
  data() {
    return {
      imageList: [],
      editDrawerIsOpen: false,
      imageData: {
        imageId: '',
        source: '',
        year: '',
        title: null,
        agency: null,
        brand: null,
        category: null,
        city: null,
        country: null,
        analyzed: false,
        type: 'Image'
      },
      masterImage:{
        imageId: '',
        source: '',
        year: '',
        title: null,
        agency: null,
        brand: null,
        category: null,
        city: null,
        country: null,
        analyzed: false,
        type: 'Image'
      },
      title: null,
      brand: null,
      agency: null,
      country: null,
      currentImage: null,
      ImageAnnotations: ImageAnnotations,
      capitalCase: capitalCase,
      selectedTag: null,
      promptClicked: false
    }
  },
  mixins: [navMixin],
  components: {
    PencilSquareIcon,
    CILogo
  },
  methods: {
    async getImage(imageId) {

      await db.collection("images").doc(imageId).get()
        .then(async (doc) => {
          const imageData = doc.data();
          this.masterImage.title = imageData.title || imageData.imageId;
          this.masterImage.brand = imageData.brand;
          this.masterImage.agency = imageData.agency;
          this.masterImage.country = imageData.country;
          this.masterImage.city = imageData.city;
          this.masterImage.category = imageData.category;
          this.masterImage.imageYear = imageData.imageYear;
          if(imageData.type == 'pdf') {
            await db.collection('images').where('sourceId', '==', imageData.imageName.split('/').pop().split('.')[0]).get()
              .then((querySnapshot) => {
                querySnapshot.forEach(async (doc) => {
                  this.imageList.push({
                    imageId: doc.id,
                    source: doc.data().source,
                    year: doc.data().imageYear,
                    analyzed: doc.data().analyzed,
                    type: doc.data().type,
                    url: 'https://storage.googleapis.com/creativeintelligence_assets/' + doc.data().imageName
                  });
                });
              });
          } else {
            this.imageList.push({
              imageId: imageData.imageId,
              source: imageData.source,
              year: imageData.imageYear,
              analyzed: imageData.analyzed,
              title: imageData.title,
              brand: imageData.brand,
              agency: imageData.agency,
              country: imageData.country,
              city: imageData.city,
              category: imageData.category,
              type: imageData.type,
              url: 'https://storage.googleapis.com/creativeintelligence_assets/' + doc.data().imageName
            });
          }
        });
    },
    getAnnotations() {
      if(this.currentImage == null) {
        return;
      } else {
        if(!this.currentImage.annotations) {
          //get from firestore
          db.collection('imageAnnotations').where('imageId', '==', this.currentImage.imageId).get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                this.currentImage.annotations = doc.data();
                this.currentImage.annotations.emotions = !this.currentImage.annotations.faces ? null : this.currentImage.annotations.faces.map((face) => face.emotions).flat().filter((value, index, self) => self.indexOf(value) === index)
                //get colors
                this.currentImage.annotations.textColor = ImageAnnotations.isDark(this.currentImage.annotations) ? '#fff' : '#000';
                this.currentImage.annotations.backgroundColor = ImageAnnotations.getBackgroundColor(this.currentImage.annotations);
              });
            });
        }
        return this.currentImage.annotations;
      }
    },
    setCurrentImage(index) {
      this.currentImage = this.imageList[index];
      this.getAnnotations();
    },
    toggleEditDrawer() {
      this.editDrawerIsOpen = false;
    },
    editFormSubmit() {
      console.log(this.masterImage)
      db.collection('images').doc(this.imageId).update({
        title: this.masterImage.title,
        brand: this.masterImage.brand,
        agency: this.masterImage.agency,
        country: this.masterImage.country,
        city: this.masterImage.city,
        category: this.masterImage.category,
        imageYear: this.masterImage.imageYear
      })
      this.toggleEditDrawer();
      this.$store.commit("SET_IMAGE_DATA", []);
      ElNotification({
        title: 'Saved!',
        type: 'success',
        duration: 3000
      })
    },
    setPrompt(tag) {
      const tagType = this.$store.state.dataCounts.find((tagType) => tagType.instances.find((instance) => instance.tag === tag)).type;
      this.$emit('setPrompt', capitalCase(tag), tagType);
      this.promptClicked = false;
    },
    confirmPrompt(tag) {
      this.promptClicked = true;
      this.selectedTag = tag;
    }
  },
  async mounted() {
    await this.getImage(this.imageId);
    this.setCurrentImage(0);
    CreativeIntelligence.setPageTitle(this.masterImage.title)
  },
  computed: {
    imageFlag() {
      return this.masterImage.country ? 'https://countryflagsapi.com/png/' + this.masterImage.country.toLowerCase() : '';
    }
  }
}

</script>