import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import { auth } from './firebaseConfig'
import {createUserWithEmailAndPassword,signInWithEmailAndPassword,signOut} from 'firebase/auth'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

const initialState = {
  user: {
      loggedIn: false,
      data: null
    },
  imageData: [],
  videoData: [],
  dataCounts: [],
  appState: {
    currentVideoPage: 1,
    currentImagePage: 1,
    currentTab: null,
    currentYear: new Date().getFullYear() - 1,
    currentPage: [
      { label: 'Videos', page: 1 },
      { label: 'Images', page: 1 }
    ],
  },
  prompts: {
  },
  expires: new Date().getTime() + 1000 * 60 * 60 * 24 * 1 // 1 day
}

const store = createStore({
  state: initialState,
  getters: {
    user(state){
      return state.user
    },
    isAdmin(state) {
      return state.user.isAdmin
    },
    dataCounts(state) {
      return state.dataCounts
    },
    imageData(state) {
      return state.imageData
    },
    videoData(state) {
      return state.videoData
    },
    prompts(state) {
      return state.prompts
    },
    appState(state) {
      return state.appState
    },
    currentYear(state) {
      return state.appState.currentYear
    },
    isExpired: state => (new Date(state.expires) < new Date())
  },
  mutations: {
    SET_USER(state, payload) {
      state.user.data = payload
    },
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_ADMIN_STATUS(state, value) {
      state.user.isAdmin = value;
    },
    SET_DATA_COUNTS(state, payload) {
      state.dataCounts = payload
    },
    SET_VIDEO_DATA(state, payload) {
      state.videoData = payload
    },
    SET_IMAGE_DATA(state, payload) {
      state.imageData = payload
    },
    SET_CURRENT_VIDEO_PAGE(state, value) {
      state.appState.currentVideoPage = value
    },
    SET_CURRENT_IMAGE_PAGE(state, value) {
      state.appState.currentImagePage = value
    },
    SET_CURRENT_TAB(state, value) {
      state.appState.currentTab = value
    },
    SET_CURRENT_PAGE(state, payload) {
      const currentPage = state.appState.currentPage.find(page => page.label === payload.label)
      if(currentPage) {
        currentPage.page = payload.page
      } else {
        state.appState.currentPage.push(payload)
      }
    },
    SET_CURRENT_YEAR(state, value) {
      state.appState.currentYear = value
    },
    CLEAR_CURRENT_PAGES(state) {
      state.appState.currentPage = [];
    },
    SET_PROMPTS(state, payload) {
      state.prompts = payload
    },
    SET_PROMPT_COLORS(state, payload) {
      state.prompts.colors = payload
    },
    SET_PROMPT_ACTIONS(state, payload) {
      state.prompts.actions = payload
    },
    SET_PROMPT_EXPRESSIONS(state, payload) {
      state.prompts.expressions = payload
    },
    SET_PROMPT_EMOTIONS(state, payload) {
      state.prompts.emotions = payload
    },
    SET_PROMPT_OBJECTS(state, payload) {
      state.prompts.objects = payload
    },
    UPDATE_EXPIRATION_DATE: (state) => {
      state.expires = new Date().getTime() + 1000 * 60 * 60 * 24 * 1;
    }
  },
  actions: {
      async register(context, { email, password, name}){
          const response = await createUserWithEmailAndPassword(auth, email, password)
          if (response) {
              context.commit('SET_USER', response.user)
              response.user.updateProfile({displayName: name})
          } else {
              throw new Error('Unable to register user')
          }
      },

      async logIn(context, { email, password }){
          const response = await signInWithEmailAndPassword(auth, email, password)
          if (response) {
            context.commit('SET_USER', response.user)
            auth.currentUser.getIdTokenResult().then((idTokenResult) => {
              context.commit('SET_ADMIN_STATUS', idTokenResult.claims.admin)
            })
            context.commit('SET_LOGGED_IN', true)
          } else {
              throw new Error('login failed')
          }
    },

    async logOut(context){
        await signOut(auth)
        context.commit('SET_USER', null)
        context.commit('SET_LOGGED_IN', false)
        context.commit('SET_VIDEO_DATA', [])
        context.commit('SET_IMAGE_DATA', [])
        context.commit('SET_CURRENT_TAB', null)
        context.commit('SET_DATA_COUNTS', [])
        context.commit('SET_PROMPTS', {})
        context.commit('SET_ADMIN_STATUS', false)
        context.commit('CLEAR_CURRENT_PAGES')
      },
    
    async fetchUser(context ,user) {
      context.commit("SET_LOGGED_IN", user !== null);
      if (user) {
        context.commit("SET_USER", {
          displayName: user.displayName,
          email: user.email
        });
      } else {
        context.commit("SET_USER", null);
      }
  }
  },
  plugins: [vuexLocal.plugin]

})



// export the store
export default store