<template>
    <div class="grid grid-cols-2 justify-items-stretch">
        <el-button class="w-16" @click="goBack">Back</el-button>
        <year-menu />
    </div>
    <div>
        <p class="text-5xl text-center font-[MagicRamen]">Stills: <em class="font-[MagicRamen]">{{ capitalCase(tag) }}</em>
            <el-button link @click="setPrompt(tag)" class="mx-5 mb-2">
                <c-i-logo color="white" border="black" class="w-8 mt-1"></c-i-logo>    
            </el-button>
        </p>
        <div class=" mt-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-1 min-h-[300px]">
            <div v-for="(image, index) in images" :key="index" class="relative overflow-hidden rounded-md image-container">
                <el-tooltip :show-after="tooltipDelay" :offset="tooltipOffset" effect="light" popper-class="gallery-tooltip">
                    <template #content>
                        <p class="text-lg font-[MagicRamen]">{{ image.video.title }}</p>
                        <p class="text-sm">{{ image.video.brand }} / {{ image.video.agency }}</p>
                        <p class="text-sm">{{ new Date(image.offset * 1000).toISOString().substring(14, 19) }}</p>
                    </template>
                    <div class="gallery-image h-[8.5rem]">
                        <el-image loading="lazy" @click="viewVideo(image.video.id, image.offset)" :src="image.url" :alt="image.video" object-fit="cover" class="clickable">
                            <template #loading>
                                <div class="image-slot">
                                    <el-icon><icon-picture /></el-icon>
                                </div>
                            </template>    
                        </el-image>
                    </div>
                </el-tooltip>
            </div>
        </div>
    </div>
    <LoaderOverlay :data-loaded="dataLoaded"></LoaderOverlay>
</template>

<style scoped>
/* This styles the container div */
:host {
  padding: 1rem;
}
/* This styles the images within the grid */
img {
  transition: transform 0.3s ease-in-out;
}
/* This enlarges the image on hover */
div.image-container:hover img {
  transform: scale(1.05);
}
.gallery-tooltip {
    border: 1px solid #000;
}
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 30px;
}

.image-slot .el-icon {
  font-size: 30px;
}
</style>

<script>
import firebase from "../firebaseInit";
import navMixin from '../mixins/navMixin';
import { Picture as IconPicture } from '@element-plus/icons-vue'
import { capitalCase } from 'change-case'
import { CreativeIntelligence } from '../lib/CreativeIntelligence'
import YearMenu from '../components/YearMenu.vue'
import CILogo from '../components/CILogo.vue'
import LoaderOverlay from '../components/LoaderOverlay.vue'

export default {
    name: 'GalleryComponent',
    mixins: [navMixin],
    data() {
        return {
            dataLoaded: false,
            possibleImages: [],
            type: this.$route.params.type,
            tag: this.$route.params.tag,
            height: null,
            tooltipOffset: -20,
            tooltipDelay: 500,
            capitalCase: capitalCase,
            CreativeIntelligence: CreativeIntelligence
        }
    },
    components: {
        IconPicture,
        YearMenu,
        CILogo,
        LoaderOverlay
    },
    methods: {
        async getImages() {
            const db = firebase.firestore();
            const taggedImagesCollection = db.collection("taggedFiles");
            const taggedImages = await taggedImagesCollection.where("tag", "==", this.tag).get();
            const videos = this.$store.state.videoData;
            taggedImages.docs.forEach((doc) => {
                doc.data().images.map((image) => {
                    const videoId = image.split('/')[0];
                    const offset = image.split('.')[0].split('_')[1];
                    const video = videos.find((video) => video.name === videoId);
                    if(!video) {
                        console.log(image);
                    }
                    
                    this.possibleImages.push({
                        offset: offset,
                        video: video,
                        url: 'https://storage.googleapis.com/creativeintelligence_assets/' + image
                    });
                });
            });
            this.loading = false;
        },
        calculateHeight() {
            this.$nextTick(() => {
                const images = this.$el.querySelectorAll('img')
                if (images.length > 0) {
                    const containerWidth = this.$el.offsetWidth
                    //const imageWidth = images[0].naturalWidth
                    const aspectRatio = images[0].naturalHeight / images[0].naturalWidth
                    this.height = aspectRatio * containerWidth
                }
            })
        },
        viewVideo(videoId, offset) {
            this.$router.push({ name: 'View', params: { type: 'Video', id: videoId, offset: offset } });
        },
        setPrompt(tag) {
            this.loading = true;
            const tagType = this.$store.state.dataCounts.find((tagType) => tagType.instances.find((instance) => instance.tag === tag)).type;
            this.$emit('setPrompt', tag, tagType);
            this.loading = false;
        }
    },
    mounted() {
        this.getImages();
        CreativeIntelligence.setPageTitle(this.capitalCase(this.tag));
    },
    computed: {
        images() {
            var shuffledImages = this.possibleImages.filter((image) => image.video?.year == this.$store.getters.currentYear).sort(() => Math.random() - 0.5);
            return shuffledImages.length < 300 ? shuffledImages : shuffledImages.slice(0, 300);
        }
    },
    updated() {
        this.dataLoaded = true
    }
}

</script>