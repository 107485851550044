<template>
  <link rel="stylesheet" href="https://unpkg.com/vue-multiselect@2.1.7/dist/vue-multiselect.min.css" />
  <el-steps :active="activeStep" simple id="promptSteps" finish-status="success">
    <el-step title="Step 1" ref="step1" />
    <el-step title="Step 2" ref="step2" />
    <el-step title="Step 3" ref="step3" />
  </el-steps>
  <Transition>
    <div class="mt-5 grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-0" v-if="activeStep == 0">
      <div>
        <VueMultiselect v-model="objects" :multiple="true" tag-position="bottom" :max="maxSelections" :open-direction="'below'" :options="objectsList" :searchable="true" :close-on-select="false" :show-labels="false" placeholder="Select Objects">
          <template #noResult>
            No Results
          </template>
        </VueMultiselect>
      </div>
      <div>
        <VueMultiselect v-model="actions" :multiple="true" :max="maxSelections" :open-direction="'below'" :options="actionsList" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Select Actions">
          <template #noResult>
            No Results
          </template>
        </VueMultiselect>
      </div>
      <div>
        <VueMultiselect v-model="expressions" :multiple="true" :max="maxSelections" :open-direction="'below'" :options="expressionsList" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Select Expressions">
          <template #noResult>
            No Results
          </template>
        </VueMultiselect>
      </div>
      <div>
        <VueMultiselect v-model="colors" :multiple="true" :max="maxSelections" :open-direction="'below'" :options="colorsList" :searchable="true" :close-on-select="true" :allow-empty="true" :show-labels="false" placeholder="Select Colors">
          <template #noResult>
            No Results
          </template>
        </VueMultiselect>
      </div>
      <el-button :disabled="!tagsComplete" @click="activeStep = 1" class="mt-5 md:col-span-2 ml-2 w-64 justify-self-center" type="primary">Next</el-button>
      <el-button :disabled="false" @click="shuffleTags" class="ml-2 w-32 justify-self-center md:my-0 my-5" type="primary">Shuffle</el-button>
      <el-button :disabled="false" @click="clearTags" class="ml-2 w-32 justify-self-center" type="primary">Clear</el-button>
    </div>
  </Transition>
  <Transition>
    <div v-if="activeStep == 1" class="mt-5">
      <div class="flex justify-center items-center md:flex-row flex-col">
        <p class="text-lg mr-5 inline align-middle">Choose a style:</p>
        <el-radio-group v-model="selectedStyle" size="large" class="align-middle">
          <el-radio-button v-for="style in stylePrompts" :key="style" :label="style.label" />
        </el-radio-group>
      </div>
      <el-divider />
      <div class="flex justify-center items-center md:flex-row flex-col">
        <p class="text-lg mr-5 inline align-middle" >Choose a channel:</p>
        <el-radio-group v-model="selectedChannel" size="large" class="align-middle">
          <el-radio-button v-for="channel in channelPrompts" :key="channel" :label="channel.label" />
        </el-radio-group>
      </div>
      <div class="mt-5 grid grid-cols-2 gap-2">
        <el-button :disabled="false" @click="activeStep = 0" class="ml-2 w-32 justify-self-center" type="primary">Prev</el-button>
        <el-button :disabled="!styleComplete" @click="activeStep = 2" class="ml-2 w-32 justify-self-center" type="primary">Next</el-button>
      </div>
    </div>
  </Transition>
  <Transition>
    <div class="grid mt-5 grid-cols1" v-if="activeStep == 2">
      <p class="justify-self-center text-xl">Here is your prompt:</p>
      <p class="w-4/5 justify-self-center rounded-xl border-2 border-color: rgb(0 0 0); p-5 my-5 font-bold">{{ clipboardCopy }}</p>
      <div class="mt-5 grid grid-cols-2 gap-2">
        <el-button :disabled="false" @click="activeStep = 1" class="ml-2 w-32 justify-self-center" type="primary">Prev</el-button>
        <el-button :disabled="false" @click="copyPrompt"  class="ml-2 w-40 justify-self-center" type="primary">Copy to Clipboard</el-button>
      </div>
    </div>
  </Transition>
  <Transition>
    <div class="grid mt-5 grid-cols1" v-if="activeStep == 3">
      <p class="justify-self-center text-xl">You're all set! Try out your prompt in MidJourney, then come back and remix into new combinations!</p>
      <div class="mt-5 grid grid-cols-2 gap-2">
        <el-button :disabled="false" @click="activeStep = 0" class="ml-2 w-32 justify-self-center" type="primary">Start Over</el-button>
        <el-button :disabled="false" @click="activeStep = 1" class="ml-2 w-40 justify-self-center" type="primary">New Style/Channel</el-button>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  height: 0;
}
</style>

<script>
import { MediaGetters } from '../lib/MediaGetters'
import VueMultiselect from 'vue-multiselect'
import { capitalCase } from "change-case"
import firebase from "../firebaseInit";
import { copyText } from 'vue3-clipboard'

const db = firebase.firestore();

export default {
    name: 'PromptBuilder',
    data() {
        return {
            actionsList: [],
            colorsList: [],
            expressionsList: [],
            objectsList: [],
            MediaGetters: MediaGetters,
            capitalCase: capitalCase,
            tagList: {},
            activeStep: 0,
            stylePrompts: [{label:'No Style', prompt:''}],
            channelPrompts: [{label:'No Channel', prompt:''}],
            selectedStyle: '',
            selectedChannel: '',
            maxSelections: 4,
        }
    },
    components: {
      VueMultiselect
    },
    methods: {
      async getPrompts() {
        const prompts = await db.collection('prompts').get().then(query => query.docs.map(doc => doc.data()))
        this.stylePrompts = this.stylePrompts.concat(prompts.filter(prompt => prompt.type === 'style').sort())
        this.channelPrompts = this.channelPrompts.concat(prompts.filter(prompt => prompt.type === 'channel').sort())
      },
      actionsSearch(queryString, cb) {
          const actions = Object.values(this.actionsList)
          const results = queryString ? actions.filter(action => action.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1) : actions
          cb(results)
      },
      colorsSearch(queryString, cb) {
          const colors = Object.values(this.colorsList)
          const results = queryString ? colors.filter(color => color.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1) : colors
          cb(results)
      },
      expressionsSearch(queryString, cb) {
          const expressions = Object.values(this.expressionsList)
          const results = queryString ? expressions.filter(expression => expression.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1) : expressions
          cb(results)
      },
      objectsSearch(queryString, cb) {
          const objects = Object.values(this.objectsList)
          const results = queryString ? objects.filter(object => object.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1) : objects
          cb(results)
      },
      handleSelect(item) {
          console.log(item)
      },
      shuffleTags() {
          //get 3 of everyting
          this.actions = []
          this.colors = []
          this.expressions = []
          this.objects = []
          for (let i = 0; i < 3; i++) {
              this.actions.push(this.actionsList[Math.floor(Math.random() * this.actionsList.length)])
              this.colors.push(this.colorsList[Math.floor(Math.random() * this.colorsList.length)])
              this.expressions.push(this.expressionsList[Math.floor(Math.random() * this.expressionsList.length)])
              this.objects.push(this.objectsList[Math.floor(Math.random() * this.objectsList.length)])
          }
      },
      clearTags()
      {
        this.actions = []
        this.colors = []
        this.expressions = []
        this.objects = []
      },
      copyPrompt() {
        copyText(this.clipboardCopy, undefined, (error) => {
          if (error) {
            console.log(error)
          } else {
            this.activeStep = 3
          }
        })
      },
      setPrompt(tag,type){
        switch (type) {
          case 'actions':
            this.actions ||= []
            if(this.actions.includes(tag)) return
            this.actions.push(tag)
            if(this.actions.length > this.maxSelections) this.actions.shift()
            break;
          case 'colors':
            this.actions ||= []
            if(this.actions.includes(tag)) return
            this.actions.push(tag)
            if(this.actions.length > this.maxSelections) this.actions.shift()
            break;
          case 'expressions':
          case 'emotions':
            this.expressions ||= []
            if(this.expressions.includes(tag)) return
            this.expressions.push(tag)
            if(this.expressions.length > this.maxSelections) this.expressions.shift()
            break;
          case 'objects':
            this.objects ||= []
            if(this.objects.includes(tag)) return
            this.objects.push(tag)
            if(this.objects.length > this.maxSelections) this.objects.shift()
            break;
          default:
            break;
        }
      }
    },
    async mounted() {
      await this.getPrompts()
      await MediaGetters.getDataCounts().then(() => {
        this.tagList = this.$store.state.dataCounts
        this.actionsList = this.tagList?.find((tags) => tags.type == 'actions').instances.map((action) => action.tag).sort()
        this.colorsList = this.tagList?.find((tags) => tags.type == 'colors').instances.map((color) => color.tag).sort()
        const expressionsList = this.tagList?.find((tags) => tags.type == 'expressions').instances.map((expression) => expression.tag)
        const emotionsList = this.tagList?.find((tags) => tags.type == 'emotions').instances.map((emotion) => capitalCase(emotion.tag))
        this.expressionsList = [...new Set(expressionsList.concat(emotionsList))].sort()
        this.objectsList = this.tagList?.find((tags) => tags.type == 'objects').instances.map((object) => object.tag).sort()
      })
    },
    computed: {
      tagsComplete() {
        return ((this.colors || []).length > 0) && ((this.actions || []).length > 0) && ((this.expressions || []).length > 0) && ((this.objects || []).length > 0)
      },
      styleComplete() {
        return this.selectedStyle != '' && this.selectedChannel != ''
      },
      clipboardCopy() {
        var objects = [...this.objects]
        //randomize objects
        objects.sort(() => Math.random() - 0.5)

        var prompt = `${this.objects.join(', ')}, ${this.actions.join(', ')}, ${this.expressions.join(', ')}, ${this.colors.join(', ')}`
        if(this.selectedChannel != 'No Channel') {
          prompt = `${this.channelPrompts.find(prompt => prompt.label === this.selectedChannel).prompt} showing ${prompt}`
        }
        if(this.selectedStyle != 'No Style') {
          prompt += `, ${this.stylePrompts.find(prompt => prompt.label === this.selectedStyle).prompt}`
        }
        prompt = 'Award-winning ad, ' + prompt
        return prompt
      },
      colors: {
        get() {
          return this.$store.getters.prompts.colors
        },
        set(colors) {
          this.$store.commit('SET_PROMPT_COLORS', colors)
        },
      },
      actions: {
        get() {
          return this.$store.getters.prompts.actions
        },
        set(actions) {
          this.$store.commit('SET_PROMPT_ACTIONS', actions)
        },
      },
      expressions: {
        get() {
          return this.$store.getters.prompts.expressions
        },
        set(expressions) {
          this.$store.commit('SET_PROMPT_EXPRESSIONS', expressions)
        },
      },
      objects: {
        get() {
          return this.$store.getters.prompts.objects
        },
        set(objects) {
          this.$store.commit('SET_PROMPT_OBJECTS', objects)
        },
      },

    }
    
}
</script>
