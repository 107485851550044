<template>
  <div>
    <el-button @click="goBack">Back</el-button>
    <p class="my-5 text-xl">{{ brand }}</p>
    <media-list v-if="videoData.length > 0" :mediaData="videoData" ref="brandVideoListComponentRef" hideSearch=true label="BrandVideos"/>
    <media-list v-if="imageData.length > 0" :mediaData="imageData" ref="brandImageListComponentRef" hideSearch=true label="BrandImages"/>
  </div>
</template>
  
<script>
import navMixin from '../mixins/navMixin.js';
import mediaQuery from '../mixins/mediaQuery.js';
import MediaList from '../components/MediaList.vue';

export default {
  name: "ViewBrandComponent",
  mixins: [navMixin, mediaQuery],
  components: {
    MediaList,
  },
  data() {
    return {
      brand: this.$route.params.brand
    };
  },
  created() {
    this.getVideos().then (() => {
      this.$refs.brandVideoListComponentRef?.init();
    })
    this.getImages().then (() => {
      this.$refs.brandImageListComponentRef?.init();
    })
  },
  computed: {
    videoData() {
      return this.$store.state.videoData.filter((video) => {
        return video.brand === this.brand;
      });
    },
    imageData() {
      return this.$store.state.imageData.filter((image) => {
        return image.brand === this.brand;
      });
    }
  }
}
</script>
