export default {
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        viewBrand(brand) {
            this.$router.push({ name: 'Brand', params: { brand: brand } });
        },
        viewAgency(agency) {
            this.$router.push({ name: 'Agency', params: { agency: agency } });
        }
    }
}