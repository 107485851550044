<template>
  <div class="grid grid-cols-2 justify-items-stretch">
    <div>
      <div>
        <el-radio v-model="dataToDisplay" label="instances">Show Instances</el-radio>
        <el-radio v-model="dataToDisplay" label="totals">Show Total Seconds</el-radio>
        <el-radio v-model="dataToDisplay" label="durations">Show Durations</el-radio>
      </div>
      <div v-if="dataToDisplay === 'instances'">
        <p>Showing the number of videos containing each item</p>
        <p class="text-sm">(Average duration per video in parentheses)</p>
      </div>
      <div v-if="dataToDisplay === 'totals'">
        <p>Showing the totals number of seconds containing each item across all videos</p>
        <p class="text-sm">(Number of videos in parentheses)</p>
      </div>
      <div v-if="dataToDisplay === 'durations'">
        <p>Showing the average number of seconds each item appears in a video</p>
        <p class="text-sm">(Number of videos in parentheses)</p>
      </div>
    </div>
    <year-menu />
  </div>
  <div id="dataCountContainer" class="grid grid-cols-5 mt-5">
    <div>
      <p class="text-xl font-[MagicRamen]">Actions</p>
      <ul>
        <li v-for="(action) in currentYearCounts('actions')" :key="action">
          <span class="clickable" @click="showGallery('actions',action.tag)">{{ action.tag }}: {{ action.value }} <span class="text-xs">({{ currentYearCounts('actions',secondaryData).find((secondary) => secondary.tag == action.tag).value.toString() }})</span></span>
        </li>
      </ul>
    </div>
    <div>
      <p class="text-xl font-[MagicRamen]">Expressions</p>
      <ul>
        <li v-for="(expression) in currentYearCounts('expressions')" :key="expression">
          <span class="clickable" @click="showGallery('expressions',expression.tag)">{{ expression.tag }}: {{ expression.value }} <span class="text-xs">({{ currentYearCounts('expressions',secondaryData).find((secondary) => secondary.tag == expression.tag).value.toString() }})</span></span>
        </li>
      </ul>
    </div>
    <div>
      <p class="text-xl font-[MagicRamen]">Colors</p>
      <ul>
        <li v-for="(color) in currentYearCounts('colors')" :key="color">
          <span class="clickable" @click="showGallery('colors',color.tag)">{{ capitalCase(color.tag) }} <span class="color-square" :style="'background-color: ' + color.tag"></span>: {{ color.value }} <span class="text-xs">({{ currentYearCounts('colors',secondaryData).find((secondary) => secondary.tag == color.tag).value.toString() }})</span></span>
        </li>
      </ul>
    </div>
    <div>
      <p class="text-xl font-[MagicRamen]">Emotions</p>
      <ul>
        <li v-for="(emotion) in currentYearCounts('emotions')" :key="emotion">
          <span class="clickable" @click="showGallery('emotions',emotion.tag)">{{ capitalCase(emotion.tag) }}: {{ emotion.value }} <span class="text-xs">({{ currentYearCounts('emotions',secondaryData).find((secondary) => secondary.tag == emotion.tag).value.toString() }})</span></span>
        </li>
      </ul>
    </div>
    <div>
      <p class="text-xl font-[MagicRamen]">Objects</p>
      <ul>
        <li v-for="(object) in currentYearCounts('objects')" :key="object">
          <span class="clickable" @click="showGallery('objects',object.tag)">{{ object.tag }}: {{ object.value }} <span class="text-xs">({{ currentYearCounts('objects',secondaryData).find((secondary) => secondary.tag == object.tag).value.toString() }})</span></span>
        </li>
      </ul>
    </div>
  </div>
  <LoaderOverlay :data-loaded="dataLoaded" :minimum-threshold="0"></LoaderOverlay>
</template>

<script>
import { capitalCase } from "change-case";
import { MediaGetters } from "../lib/MediaGetters";
import YearMenu from "./YearMenu";
import { CreativeIntelligence } from "@/lib/CreativeIntelligence";
import LoaderOverlay from "./LoaderOverlay";

export default {
  name: 'DataCountViewer',
  data() {
    return {
      capitalCase: capitalCase,
      dataToDisplay: 'instances',
      countsByYear: [],
      actions: {},
      expressions: {},
      emotions: {},
      objects: {},
      colors: {},
      MediaGetters: MediaGetters,
      CreativeIntelligence: CreativeIntelligence,
      dataLoaded: false
    }
  },
  components: {
    YearMenu,
    LoaderOverlay
  },
  methods: {
    sortCounts(counts) {
      if(!counts) return;
      const sorted = Object.entries(counts)
        .sort(([,a],[,b]) => b-a)
      
      //convert to object properties
      var sortedObj = {}
      sorted.forEach((item) => {
        sortedObj[item[0]] = item[1];
      })
      return sortedObj;
    },
    showGallery(type,tag) {
      this.$router.push({ name: 'Gallery', params: { type: type, tag: tag } })
    },
    currentYearCounts(type, display = this.dataToDisplay) {
      if(!this.currentYearData) return;
      return this.currentYearData[type][display]
    }
  },
  computed: {
    secondaryData() {
      switch (this.dataToDisplay) {
        case 'totals':
          return 'instances';
        case 'instances':
        return 'durations';
        case 'durations':
        return 'instances';
        default:
        return 'instances';
      }
    },
    currentYearData() {
      if (this.countsByYear.length == 0) return;
      return this.countsByYear.find((item) => item.year === this.currentYear.toString())
    },
    currentYear() {
      return this.$store.getters.currentYear || new Date().getFullYear() - 1;
    }
  },
  async mounted() {
    await MediaGetters.getDataCounts().then(() => {
      const possibleYears = [...new Set(this.$store.getters.dataCounts.map((item) => item.year))]
      //group media data by year
      possibleYears.forEach((year) => {
        const yearData = this.$store.getters.dataCounts.filter((item) => item.year === year)
        const actions = yearData.find((item) => item.type === 'actions')
        const expressions = yearData.find((item) => item.type === 'expressions')
        const emotions = yearData.find((item) => item.type === 'emotions')
        const objects = yearData.find((item) => item.type === 'objects')
        const colors = yearData.find((item) => item.type === 'colors')
        this.countsByYear.push({
          year: year,
          actions: actions,
          expressions: expressions,
          emotions: emotions,
          objects: objects,
          colors: colors
        })
      })
    })
  },
  updated() {
      this.dataLoaded = true
  }
}
</script>
