<template>
    <div v-if="$isMobile() && user.loggedIn" id="header" class="bg-black text-white p-2.5 header-mobile">
      <span class="text-xs font-[MagicRamen]">NYF Creative Intelligence Prompt Builder</span>
      <el-button v-if="user.data.email" size="small" class="float-right" @click="logout">Logout</el-button>
    </div>
    <div v-else-if="user.loggedIn" id="header" class="flex bg-black text-white px-2.5 h-28">
      <el-dropdown class="flex-grow place-self-center" @command="navMenuSelect">
          <span class="h-12 flex ml-10">
            <el-image :src="logoUrl" id="ciLogo" ></el-image>
            <span class="xl:text-xl font-[MagicRamen] text-white ml-1 min-w-[10rem] leading-5 mt-1.5 xl:mt-0">New York Festivals<br/>Creative Intelligence</span>
            <chevron-down-icon class="text-white mx-1.5 w-4"></chevron-down-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item class="font-[MagicRamen] w-48" :command="'/'"><span class="text-base">Media</span></el-dropdown-item>
              <el-dropdown-item class="font-[MagicRamen] w-48" :command="'/data'"><span class="text-base">Data</span></el-dropdown-item>
            </el-dropdown-menu>
          </template>
      </el-dropdown>
      <div class="flex-none w-auto h-full">
        <img src="@/assets/header.png" alt="header" class="h-full" />
      </div>
      <div id="header-email" v-if="user.loggedIn" class="flex-grow flex items-center justify-end place-self-center mr-1 flex-shrink-0 min-w-0 text-right">
        <button class="mx-3 bg-red-600 hover:bg-[#fc4b00] text-white font-bold py-2 px-4 rounded-full border-b-4 border-red-800 hover:border-[#ca3c00]" @click="promptDrawerOpen = true">
          <c-i-logo :color="logoColor" :border="logoColor" class="w-7 h-7"></c-i-logo>
        </button>
        <p class="mx-2">Hi, {{user.data.email}}</p>
        <el-button size="large" class="ml-2 justify-self-end" @click="logout">Logout</el-button>
      </div>
    </div>
    <div class="grid justify-items-center">
      <main class="py-4 px-5 w-screen">
        <router-view @setPrompt="setPrompt"></router-view>
      </main>
    </div>
    <div v-if="user.loggedIn" id="footer" class="mt-3">
      <img src="@/assets/footer.svg" alt="footer" />
    </div>
  <el-drawer
    v-model="promptDrawerOpen"
    :direction="'btt'"
    size="70%"
    :with-header="false"
    :append-to-body="true">
    <prompt-builder ref="promptBuilderRef"></prompt-builder>
  </el-drawer>
  

</template>

<style scoped>


</style>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import PromptBuilder from "@/components/PromptBuilder.vue";
import logoUrl from '@/assets/Logo_Color.svg';
import CILogo from '@/components/CILogo.vue';
import { CreativeIntelligence } from '@/lib/CreativeIntelligence.js';
import { ChevronDownIcon } from "@heroicons/vue/24/solid";

export default {
  metaInfo() {
    return {
      title: 'Creative Intelligence',
      titleTemplate: '%s | Creative Intelligence'
    }
  },
  data() {
    return {
      promptDrawerOpen: false,
      logoUrl: logoUrl,
      logoColor: '#ffffff'
    }
  },
  components: {
    PromptBuilder,
    CILogo,
    ChevronDownIcon
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    goHome() {
      this.$router.push('/')
    },
    async logout() {
      await this.$store.dispatch('logOut')
      this.$router.push('/login')
    },
    setPrompt(tag,type) {
      this.promptDrawerOpen = true
      setTimeout(() => {
            this.$refs.promptBuilderRef.setPrompt(tag,type)
          }, 100);
    },
    getNextColor() {
      //get random color from brand colors
      const newColor = CreativeIntelligence.brandColors.filter((color) => color != this.logoColor)[Math.floor(Math.random() * CreativeIntelligence.brandColors.length)];
      this.logoColor = newColor
      setTimeout(() => {
            this.getNextColor()
          }, 3000);
    },
    navMenuSelect(command) {
      this.$router.push(command)
    }
  },
  setup() {
    const store = useStore()

    const user = computed(() => {
      return store.getters.user;
    });


    //add current route as page class
    const route = computed(() => {
      return store.getters.route;
    });
        
    return { user, route }
  },
  mounted() {
    this.getNextColor()
  },
  computed: {
    pageTitle() {
      return this.$route.meta.title
    },
    isExpired() {
      return this.$store.getters.isExpired
    }
  }
};

</script>