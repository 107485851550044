import firebase from "../firebase/init";
import store from "../store";

const db = firebase.firestore();

export const MediaGetters = {
    getImages: async function() {
        var imageData = [];
        //get cached data if available
        if ((store.state.imageData && store.state.imageData.length > 0) && !store.state.isExpired) {
           return;
        }

        var imageQuery = await db.collection("appLists").doc('images').get().then(async (doc) => {
            imageData = doc.data().images;
        }).catch(async (error) => {
            console.log(error);
        });

        await Promise.all([imageQuery]).then(async () => {
            //cache data
            store.commit('SET_IMAGE_DATA', imageData)
            store.commit("UPDATE_EXPIRATION_DATE");
            return;
        });
    },
    getVideos: async function() {
        var videoData = [];
        //get cached data if available
        if ((store.state.videoData && store.state.videoData.length > 0) && !store.state.isExpired) {
           return;
        }

        var videoQuery = await db.collection("appLists").doc('videos').get().then(async (doc) => {
            videoData = doc.data().videos;
        }).catch(async (error) => {
            console.log(error);
        });

        await Promise.all([videoQuery]).then(async () => {
            //cache data
            Object.freeze(videoData);
            store.commit('SET_VIDEO_DATA', videoData)
            store.commit("UPDATE_EXPIRATION_DATE");
            return;
        });
    },
    getDataCounts: async function() {
      var dataCounts = store.state.dataCounts;
      if (!dataCounts.colors || store.getters.isExpired) {
        dataCounts = await db.collection('tagLists').get().then((querySnapshot) => {
          return querySnapshot.docs.map((doc) => doc.data());
        });
        dataCounts = dataCounts.filter((item) => item.type);
        store.commit("SET_DATA_COUNTS", dataCounts);
        store.commit("UPDATE_EXPIRATION_DATE");
      }
    }
}

      