<template>
  <div class="w-full justify-items-center ml-5">
    <div class="flex flex-row-reverse text-xs mr-5">
      <p class="text-xs"><span class="font-black">Tip:</span> Click on an Emotion, Action, Expression, or Object to add it to your Prompt!</p>
      <c-i-logo color="white" border="black" class="w-4 h-4 mr-1"></c-i-logo>
    </div>
    <el-timeline class="w-11/12" id="timeline">
        <el-timeline-item v-for="item in filteredTimeline" placement="top" :ref="'timelineOffset' + item.offset" :color="item.colorPalette[0].hexCode" :key="item.offset" :timestamp="item.timestamp" :offset="item.offset" v-bind:class="{currentTimestamp:item.offset == currentOffset}">
          <el-card :style="'background: ' + item.backgroundColor + '; color: ' + item.textColor">
            <div class="clearfix">
              <div class="mb-5">
                <el-tag class="float-left mr-3" :type="['warning','','success','info'][item.shotIndex % 4]">Shot {{ item.shotIndex }}</el-tag>
                <ForwardIcon @click="offsetJump(item.offset)" class="jumpIcon icon stroke-0 clickable" />
              </div>
              <p v-if="item.emotions">EMOTIONS:
                <span v-for="(emotion, index) in item.emotions" :key="emotion" class="clickable" @click="confirmPrompt(emotion)">{{ ImageAnnotations.emotionEmojis.find((emotionEmoji) => emotionEmoji.emotion == emotion).emoji }}<span>{{ index < item.emotions.length - 1 ? ', ' : '' }}</span></span>
              </p>
              <p v-if="item.actions?.length">ACTIONS: 
                <span v-for="(action, index) in item.actions" :key="action" class="clickable" @click="confirmPrompt(action)">{{ action }}<span>{{ index < item.actions.length - 1 ? ', ' : '' }}</span></span>
              </p>
              <p v-if="item.expressions?.length">EXPRESSIONS: 
                <span v-for="(expression, index) in item.expressions" :key="expression" class="clickable" @click="confirmPrompt(expression)">{{ expression }}<span>{{ index < item.expressions.length - 1 ? ', ' : '' }}</span></span>
              </p>
              <p v-if="item.faces?.length">PEOPLE: {{ ImageAnnotations.getPeople(item) }}</p>
              <p>OBJECTS: 
                <span v-for="(object, index) in item.objects" :key="object" class="clickable" @click="confirmPrompt(object)">{{ object }}<span>{{ index < item.objects.length - 1 ? ', ' : '' }}</span></span>
              </p>
              <p>CATEGORIES: {{  item.objectCategories?.join(', ') }}</p>
            </div>
          </el-card>
        </el-timeline-item>
    </el-timeline>
  </div>
  <el-dialog v-model="promptClicked" title="Prompt Builder" width="30%" center>
    <span>
      Add <b>{{ capitalCase(selectedTag) }}</b> to prompt?
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="promptClicked = false">Cancel</el-button>
        <el-button type="primary" @click="setPrompt(selectedTag)">
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style>
/*suppress tooltip cancel*/
.timelinePopup .el-button.is-text {
  display: none;
}
</style>
<script>
import firebase from "../firebaseInit";
import { ImageAnnotations } from "@/lib/ImageAnnotations"
import { ForwardIcon }  from '@heroicons/vue/24/solid';
import { capitalCase } from "change-case";
import CILogo from "@/components/CILogo.vue";

export default {
  name: 'TimelineViewer',
  props: {
    videoData: {
      type: Object,
      default() {
        return {};
      }
    },
    filters: {
      type: Object,
      default() {
        return {};
      }
    },
    currentOffset: {
      type: Number,
      default() {
        return 0;
      }
    }
  },
  components: {
    ForwardIcon,
    CILogo
  },
  data() {
    return {
      timelineData: [],
      ImageAnnotations: ImageAnnotations,
      capitalCase: capitalCase,
      promptClicked: false,
      selectedTag: null
    }
  },
  methods: {
    async init() {
      const db = firebase.firestore();
      await db.collection("timelines").doc(this.videoData.videoId).get()
      .then((doc) => {
        const data = doc.data();
        this.timelineData = data.timeline;
        this.timelineData.map((item) => {
          //convert offset to hour:minute:second
          item.timestamp = new Date(item.offset * 1000).toISOString().substring(14, 19);
         // item.emotions = !this.currentImage.annotations.faces ? null : this.currentImage.annotations.faces.map((face) => face.emotions).flat().filter((value, index, self) => self.indexOf(value) === index)
          //get colors
          item.textColor = ImageAnnotations.isDark(item) ? '#fff' : '#000';
          item.backgroundColor = ImageAnnotations.getBackgroundColor(item);
        });
      });
    },
    async updateTimeline(offset) {
      if(this.$refs['timelineOffset' + offset][0]) {
        this.$refs['timelineOffset' + offset][0].$el.scrollIntoView({block: "center", behavior: "auto"});
      }
    },
    offsetJump(offset) {
      this.$emit('offsetJump', offset);
    },
    setVideoStart() {
      this.$emit('offsetJump', this.filteredTimeline[0].offset);
      this.$emit('timelineLength', this.filteredTimeline.length);
    },
    setPrompt(tag) {
      const tagType = this.$store.state.dataCounts.find((tagType) => tagType.instances.find((instance) => instance.tag === tag)).type;
      this.$emit('setPrompt', capitalCase(tag), tagType);
      this.promptClicked = false;
    },
    confirmPrompt(tag) {
      this.promptClicked = true;
      this.selectedTag = tag;
    }
 },
  computed: {
    filteredTimeline() {
      return this.timelineData
        .filter((item) => {
          if (this.filters.emotions.length > 0) {
            return this.filters.emotions.every((emotion) => item.emotions?.includes(emotion));
          }
          return true;
        })
        .filter((item) => {
          if (this.filters.actions.length > 0) {
            return this.filters.actions.every((action) => item.actions?.includes(action));
          }
          return true;
        })
        .filter((item) => {
          if (this.filters.expressions.length > 0) {
            return this.filters.expressions.every((expression) => item.expressions?.includes(expression));
          }
          return true;
        })
        .filter((item) => {
          if (this.filters.objects.length > 0) {
            return this.filters.objects.every((object) => item.objects?.includes(object));
          }
          return true;
        })
        .filter((item) => {
          if (this.filters.objectCategories.length > 0) {
            return this.filters.objectCategories.every((objectCategory) => item.objectCategories?.includes(objectCategory));
          }
          return true;
        })
    },
    hasFilter() {
      return this.filters.emotions.length > 0 || this.filters.actions.length > 0 || this.filters.expressions.length > 0 || this.filters.objects.length > 0 || this.filters.objectCategories.length > 0;
    },
  },
}
</script>