<template>
  <DataCountViewer></DataCountViewer>
</template>

<script>
import DataCountViewer from "../components/DataCountViewer.vue"

export default {
  name: "ViewDataComponent",
  components: {
    DataCountViewer
  }
}
</script>