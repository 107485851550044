<template>
  <div id="videoHeader" class="w-full grid -mt-5">
    <div class="justify-self-center mb-4 text-xl font-[MagicRamen]">"{{ videoData.title }}" ( <span class="clickable font-[MagicRamen]" @click= "viewBrand(videoData.brand)">{{ videoData.brand }}</span> / <span class="clickable font-[MagicRamen]" @click= "viewAgency(videoData.agency)">{{ videoData.agency }}</span> )</div>
  </div>
  <div id="videoInfo" class="grid grid-cols-2 gap-x-5">
    <div class="w-full">
      <el-scrollbar max-height="90vh">
      <video-player @offset-update="updateTimeline" id="videoPlayer" :options="videoOptions" ref="videoPlayerComponentRef" />
      <el-button class="my-5" @click="toggleTagDrawer">See Stats <ArrowRightOnRectangleIcon class="openDrawerIcon icon"/></el-button>
      <el-drawer v-model="tagDrawerIsOpen" :withHeader="false" :direction="'ltr'" size="50%">
        <div id="aggregates">
          <h2>Click on a tag to filter the timeline.</h2>
          <el-switch class="mt-2" size="small" v-model="badgesShowTotals" active-text="Show Totals" inactive-text="Show Percentages" />
          <el-descriptions id="tags" :column="1" border>
            <el-descriptions-item label="Actions">
              <span v-for="(value, key) in videoActions" :key="key">
                <el-button @click="toggleTimelineFilter('actions',key, $event.target)" :class="{selected:filters.actions.includes(key)}">{{ key }}</el-button>
                  <el-badge :value="badgeValue(value)" :max="999" class="item"></el-badge>
                </span>
            </el-descriptions-item>
            <el-descriptions-item label="Expressions">
              <span v-for="(value, key) in videoExpressions" :key="key">
                <el-button @click="toggleTimelineFilter('expressions',key, $event.target)" :class="{selected:filters.expressions.includes(key)}">{{ key }}</el-button>
                  <el-badge :value="badgeValue(value)" :max="999" class="item"></el-badge>
                </span>
            </el-descriptions-item>
            <el-descriptions-item label="Emotions">
              <span v-for="(value, key) in videoEmotions" :key="key">
                <el-button @click="toggleTimelineFilter('emotions',key, $event.target)" :class="{selected:filters.emotions.includes(key)}">{{ key }}</el-button>
                  <el-badge :value="badgeValue(value)" :max="999" class="item"></el-badge>
                </span>
            </el-descriptions-item>
            <el-descriptions-item label="Categories">
              <span v-for="(value, key) in videoObjectCategories" :key="key">
                  <el-button @click="toggleTimelineFilter('objectCategories',key, $event.target)" :class="{selected:filters.objectCategories.includes(key)}">{{ key }}</el-button>
                  <el-badge :value="badgeValue(value)" :max="999" class="item"></el-badge>
                </span>
            </el-descriptions-item>
            <el-descriptions-item label="Objects">
              <span v-for="(value, key) in videoObjects" :key="key">
                <el-button @click="toggleTimelineFilter('objects',key, $event.target)" :class="{selected:filters.objects.includes(key)}">{{ key }}</el-button>
                  <el-badge :value="badgeValue(value)" :max="999" class="item"></el-badge>
                </span>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </el-drawer>
      <el-drawer v-model="editDrawerIsOpen" :withHeader="false" :direction="'ltr'" size="50%">
        <!-- edit form for title, brand, agency, category, directorName -->
        <el-form ref="editForm" :model="videoData" label-width="120px">
          <el-form-item label="Title">
            <el-input v-model="videoData.title" required />
          </el-form-item>
          <el-form-item label="Brand">
            <el-input v-model="videoData.brand" required />
          </el-form-item>
          <el-form-item label="Agency">
            <el-input v-model="videoData.agency" required />
          </el-form-item>
          <el-form-item label="City">
            <el-input v-model="videoData.city" />
          </el-form-item>
          <el-form-item label="Country">
            <el-input v-model="videoData.country" required />
          </el-form-item>
          <el-form-item label="Category">
            <el-input v-model="videoData.category" />
          </el-form-item>
          <el-form-item label="Year">
            <el-input v-model="videoData.videoYear" />
          </el-form-item>
          <el-form-item label="Director Name">
            <el-input v-model="videoData.director" />
          </el-form-item>
          <el-form-item label="Public">
            <el-switch v-model="videoData.public" active-text="Yes" inactive-text="No" />
            <p class="text-xs w-full">Private media is excluded from indexed lists</p>
          </el-form-item>
          <el-form-item class="mt-10">
            <el-tooltip class="item" effect="dark" content="Think before saving!" placement="top">
              <el-button type="primary" @click="editFormSubmit">Submit</el-button>
            </el-tooltip>
          </el-form-item>
        </el-form>
      </el-drawer>
      <el-card v-if="hasFilter" id="filterTags">
        <el-row justify="center">{{ formatDuration(timelineLength) }} of {{ formatDuration(this.videoData.length) }}</el-row>
        <el-tag v-for="filter in filters.objectCategories" :key="filter" :type="'danger'" closable @close="toggleTimelineFilter('objectCategories', filter)">{{ filter }}</el-tag>
        <el-tag v-for="filter in filters.objects" :key="filter" :type="'warning'" closable @close="toggleTimelineFilter('objects', filter)">{{ filter }}</el-tag>
        <el-tag v-for="filter in filters.emotions" :key="filter" :type="'info'" closable @close="toggleTimelineFilter('emotions', filter)">{{ filter }}</el-tag>
        <el-tag v-for="filter in filters.expressions" :key="filter" :type="''" closable @close="toggleTimelineFilter('expressions', filter)">{{ filter }}</el-tag>
        <el-tag v-for="filter in filters.actions" :key="filter" :type="'success'" closable @close="toggleTimelineFilter('actions', filter)">{{ filter }}</el-tag>
      </el-card>
      <PencilSquareIcon @click="toggleEditDrawer" v-if="isAdmin" class="editIcon icon my-5 secret clickable" />
      </el-scrollbar>
    </div>
    <div class="w-full">
      <el-scrollbar max-height="90vh">
        <timeline-viewer @timeline-length="setTimelineLength" @set-prompt="setPrompt" @offset-jump="offsetJump" @toggle-timeline-filter="toggleTimelineFilter" :filters="filters" :videoData="videoData" ref="timelineViewerComponentRef" />
      </el-scrollbar>
    </div>
  </div>
</template>
 
<script>
import firebase from "../firebaseInit";
import VideoPlayer from '@/components/VideoPlayer.vue';
import TimelineViewer from '@/components/TimelineViewer.vue';
import { capitalCase } from "change-case";
import { ArrowRightOnRectangleIcon, PencilSquareIcon } from '@heroicons/vue/24/solid';
import navMixin from '../mixins/navMixin';
import { ElNotification } from 'element-plus'
import { CreativeIntelligence } from '../lib/CreativeIntelligence';

const db = firebase.firestore();
const aggregatesMinimum = 10;
export default {
  props: {
    videoId: {
      type: String,
      required: true
    },
    offset: {
      type: String,
      default: '0'
    }
  },
  components: {
    VideoPlayer,
    TimelineViewer,
    ArrowRightOnRectangleIcon,
    PencilSquareIcon
  },
  mixins: [navMixin],
  data() {
    return {
      videoData: {
        videoId: null,
        videoName: null,
        source: null,
        videoYear: null,
        analyzed: false,
        length: null,
        aggregates: {},
        title: null,
        agency: null,
        brand: null,
        category: null,
        city: null,
        country: null,
        type: 'Video'
      },
      videoOptions: {
        autoplay: false,
        controls: true,
        fluid: true,
        sources: [
          {
            src: '',
            type: 'video/mp4'
          }
        ]
      },
      filters: {
        objectCategories: [],
        objects: [],
        emotions: [],
        actions: [],
        expressions: []
      },
      capitalCase: capitalCase,
      badgesShowTotals: true,
      tagDrawerIsOpen: false,
      editDrawerIsOpen: false,
      timelineLength: 0,
    }
  },
  methods: {
    async getVideo() {
      await db.collection("videos").doc(this.videoId).get()
        .then(async (doc) => {
          const data = doc.data();
          this.videoData.videoId = data.videoId;
          this.videoData.videoName = data.videoName;
          this.videoData.source = data.sourceId;
          this.videoData.videoYear = data.videoYear;
          this.videoData.analyzed = data.analyzed;
          this.videoData.length = data.length;
          this.videoData.aggregates = data.aggregates;
          this.videoData.title = data.title,
          this.videoData.agency = data.agency,
          this.videoData.brand = data.brand,
          this.videoData.category = data.category,
          this.videoData.city = data.city,
          this.videoData.country = data.country,
          this.videoData.director = data.director,
          this.videoData.public = data.public,
          this.videoOptions.sources.push({
            src: this.videoUrl
          });

          await this.$refs.videoPlayerComponentRef.init();
          await this.$refs.timelineViewerComponentRef.init();
          this.updateTimeline(this.offset);
          this.offsetJump(this.offset);
        });
    },
    badgeValue(value) {
      if(this.badgesShowTotals) {
        return value;
      } else {
        return Math.round((value / this.videoData.length) * 100);
      }
    },
    updateTimeline(offset) {
      this.$refs.timelineViewerComponentRef.updateTimeline(offset);
      window.scrollTo(0, 0);
    },
    offsetJump(offset) {
      this.$refs.videoPlayerComponentRef.offsetJump(offset);
      window.scrollTo(0, 0);
    },
    togglePlay() {
      this.$refs.videoPlayerComponentRef.togglePlay();
    },
    toggleTimelineFilter(type, value) {
      if(this.filters[type].includes(value)) {
        this.filters[type] = this.filters[type].filter((item) => item !== value);
      } else {
        this.filters[type].push(value);
      }
      this.$refs.timelineViewerComponentRef.setVideoStart();
    },
    toggleTagDrawer() {
      this.tagDrawerIsOpen = !this.tagDrawerIsOpen;
    },
    toggleEditDrawer() {
      this.editDrawerIsOpen = this.isAdmin && !this.editDrawerIsOpen;
    },
    removeFilter(filterType, filter) {
      this.$emit('toggleTimelineFilter', filterType, filter);
    },
    formatDuration(duration) {
      const minutes = Math.floor(duration / 60);
      const seconds = Math.floor(duration % 60);
      return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    },
    setTimelineLength(length) {
      this.timelineLength = length;
    },
    editFormSubmit() {
      const docToUpdate = db.collection("videos").doc(this.videoId);
      docToUpdate.update({
        title: this.videoData.title || '',
        agency: this.videoData.agency || '',
        brand: this.videoData.brand || '',
        category: this.videoData.category || '',
        city: this.videoData.city || '',
        country: this.videoData.country || '',
        director: this.videoData.director || '',
        videoYear: this.videoData.videoYear || '',
        public: this.videoData.public
      })
      this.toggleEditDrawer();
      this.$store.commit("SET_VIDEO_DATA", []);
      ElNotification({
        title: 'Saved!',
        type: 'success',
        duration: 3000
      })
    },
    setPrompt(tag, tagType) {
      this.$emit('setPrompt', tag, tagType);
    }
  },
  async mounted() {
    await this.getVideo();
    CreativeIntelligence.setPageTitle(this.videoData.title);
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    hasFilter() {
      return this.filters.emotions.length > 0 || this.filters.actions.length > 0 || this.filters.expressions.length > 0 || this.filters.objects.length > 0 || this.filters.objectCategories.length > 0;
    },
    videoUrl() {
      return 'https://storage.googleapis.com/creativeintelligence_assets/' + this.videoData.videoName.split('.')[0] + '/' + this.videoData.videoName;
    },
    videoFlag() {
      return this.videoData.country ? 'https://countryflagsapi.com/png/' + this.videoData.country.toLowerCase() : '';
    },
    videoObjects() {
      if(!this.videoData.videoId) {
        return [];
      }
      const objects = Object.entries(this.videoData.aggregates.objects);
      const factor = objects.length / aggregatesMinimum;
      objects.sort((a, b) => b[1] - a[1]);
      return Object.fromEntries(objects.filter((item) => item[1] >= factor));
    },
    videoObjectCategories() {
      if(!this.videoData.videoId) {
        return [];
      }
      const objectCategories = Object.entries(this.videoData.aggregates.objectCategories);
      const factor = objectCategories.length / aggregatesMinimum;
      objectCategories.sort((a, b) => b[1] - a[1]);
      return Object.fromEntries(objectCategories.filter((item) => item[1] >= factor));
    },
    videoEmotions() {
      if(!this.videoData.videoId) {
        return [];
      }
      const emotions = Object.entries(this.videoData.aggregates.emotions);
      const factor = emotions.length / aggregatesMinimum;
      emotions.sort((a, b) => b[1] - a[1]);
      return Object.fromEntries(emotions.filter((item) => item[1] >= factor));
    },
    videoActions() {
      if(!this.videoData.videoId) {
        return [];
      }
      const actions = Object.entries(this.videoData.aggregates.actions);
      const factor = actions.length / aggregatesMinimum;
      actions.sort((a, b) => b[1] - a[1]);
      return Object.fromEntries(actions.filter((item) => item[1] >= factor));
    },
    videoExpressions() {
      if(!this.videoData.videoId) {
        return [];
      }
      const expressions = Object.entries(this.videoData.aggregates.expressions);
      const factor = expressions.length / aggregatesMinimum;
      expressions.sort((a, b) => b[1] - a[1]);
      return Object.fromEntries(expressions.filter((item) => item[1] >= factor));
    }
  },
  created() {
    window.addEventListener('keydown', (e) => {
      if (!this.editDrawerIsOpen && (e.key == ' ' || e.key == 'Spacebar')) {
        e.preventDefault();
        this.togglePlay();
      }
    });
  }
}
</script>