<template>
  <div class="flex flex-row -my-4 -mx-5">
    <div class="w-auto">
      <img src="@/assets/login_left.svg" class="h-screen">
    </div>
    <div class="flex flex-1 flex-col min-w-max content-center">
      <div class="login align-middle flex flex-col grow place-self-center">
          <p class="text-3xl font-[MagicRamen] text-[#3030ce]">WELCOME!</p>
          <p class=" text-center text-[#3030ce]">Please enter your email address and password to continue</p>
          <el-form
            class="login-form mt-5"
            ref="form"
            @submit.prevent="Login"
          >
            <el-form-item prop="email">
              <el-input required v-model="email" placeholder="Email"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                required
                v-model="password"
                placeholder="Password"
                type="password"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <button
                type="submit"
                class="flex-grow bg-[#fc4b00] text-white font-bold text-lg justify-self-center p-2 rounded-full w-32">
              Login</button>
            </el-form-item>
            <p class="text-xs text-center clickable" @click="resetPassword">Forgot Password?</p>
          </el-form>
      </div>
      <div class="place-self-end">
        <img src="@/assets/login_right.svg" class="h-[20vh]" />
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login {
  justify-content: center;
  align-items: center;
}

.login-form {
  width: 290px;
}
.forgot-password {
  margin-top: 10px;
}
</style>
<style lang="scss">
$teal: rgb(0, 124, 137);
.login .el-input__inner:hover {
  border-color: $teal;
}
.login .el-input__prefix {
  background: rgb(238, 237, 234);
  left: 0;
  height: calc(100% - 2px);
  left: 1px;
  top: 1px;
  border-radius: 3px;
  .el-input__icon {
    width: 30px;
  }
}
.login .el-input input {
  padding-left: 35px;
}
.login .el-card {
  padding-top: 0;
  padding-bottom: 30px;
}
a {
  color: $teal;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    color: lighten($teal, 7);
  }
}
.login .el-card {
  width: 340px;
  display: flex;
  justify-content: center;
}
</style>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { auth } from '../firebaseConfig'
import { sendPasswordResetEmail } from 'firebase/auth';


export default {
  name: "LoginComponent",
  setup() {
    const email = ref('')
    const password = ref('')
    const store = useStore()
    const router = useRouter()

    if(store.getters.user.loggedIn) {
      router.push('/')
    }

    const Login = async () => {
      try {
        await store.dispatch('logIn', {
          email: email.value,
          password: password.value
        })
        router.push('/')
      }
      catch (err) {
        const errMsg = err.toString().includes('wrong-password') ? 'Wrong password' : 'User not found'
        ElMessage({ message: errMsg });
      }
    }
    return { Login, email, password }
  },
  methods: {
    resetPassword() {
    const email = prompt('Enter your email address');
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert('Password reset email sent!');
      })
      .catch((error) => {
        alert(error.message);
      });
    }
  }
};
</script>